<template>
  <b-container style="margin-top: 80px">
    <h4 class="display-3 mt-1 mb-4 font-weight-bold text-center">
      วีดีโอสอนการใช้งานระบบ
    </h4>

    <b-row v-if="isFetching">
      <b-col cols="12">
        <b-skeleton-img no-aspect height="350px"></b-skeleton-img>
        <b-skeleton-img no-aspect height="350px"></b-skeleton-img>
      </b-col>
    </b-row>

    <div v-else>
      <page-content
        v-for="(tutorial, index) in tutorials"
        :key="`tutorial-${index}`"
        class="mb-4"
      >
        <h5 class="display-4 mt-1 mb-2 font-weight-bold text-center">
          {{ tutorial.lesson_order }}. {{ tutorial.lesson_title }}
        </h5>

        <b-row align-h="center">
          <b-col cols="12">
            <a class="card-img-wrapper rounded" href="javascript:void(0)">
              <b-embed type="video" controls>
                <source
                  :src="`${tutorial.lesson_url}#t=${tutorial.lesson_cover_time}`"
                  type="video/mp4"
                />
              </b-embed>
            </a>
          </b-col>
        </b-row>
      </page-content>
    </div>
  </b-container>
</template>

<script>
import PageContent from "../../components/layout/PageContent";
import { Lesson } from "../../models";
// import PageTitle from "../../components/layout/PageTitle";

export default {
  components: {
    PageContent,
    // PageTitle
  },

  data() {
    return {
      isFetching: false,

      tutorials: [],
    };
  },

  methods: {
    async fetch() {
      let promise;

      this.isFetching = true;

      try {
        promise = await Lesson.api().findAll(
          {
            lesson_type: "tutorial",
            order: "lesson_order",
            order_by: "ASC",
          },
          { save: false }
        );

        const { data = [] } = promise.response.data;

        this.tutorials = [...data];
      } catch (error) {
        this.$toast.error(
          "ไม่สามารถโหลดวีดีโอสอนการใช้งานระบบได้ กรุณาลองใหม่อีกครั้ง"
        );
      } finally {
        this.isFetching = false;
      }

      return promise;
    },
  },

  async mounted() {
    await this.fetch();
  },
};
</script>
